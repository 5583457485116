import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../components/ui/PrimaryButton';
import { UserContext } from '../contexts/UserProvider';
const TutorialPage = () => {
  const navigate = useNavigate();
  const { defaultBuySolAmount } = useContext(UserContext);

  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCanContinue(true);
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="relative w-full h-screen">
      <div className="fixed left-0 right-0 flex w-screen items-center text-center h-screen">
        <div className="text-3xl font-bold text-white mx-auto w-full">Loading tutorial</div>
      </div>

      <video
        src="https://swipe.fun/video/tutorial.mp4"
        autoPlay
        loop
        muted
        className="absolute z-10 w-screen h-screen"
      />
      {canContinue && (
        <div className="absolute bottom-24 left-0 right-0 z-20 px-5">
          <PrimaryButton
            text="Continue"
            callback={() => {
              localStorage.setItem('tutorialSeen', Date.now().toString());
              if (defaultBuySolAmount.isZero()) {
                navigate('/onboarding');
              } else {
                navigate('/main');
              }
            }}
            fullWidth={true}
          />
        </div>
      )}
    </div>
  );
};

export default TutorialPage;
