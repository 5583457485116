import React, { useContext, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SOL_MINT } from '../common/constants';
import Card, { tokenMintToTokenCard } from '../components/Card';
import BuyTokenModal from '../components/modals/BuyTokenModal';
import SellTokenModal from '../components/modals/SellTokenModal';
import TokenMoreInfoModal from '../components/modals/TokenMoreInfoModal';
import PrimaryButton from '../components/ui/PrimaryButton';
import { mapCardTokenToMoreInfoData } from '../components/utils/moreInfoUtils';
import { UserContext } from '../contexts/UserProvider';
import { CardToken, MoreInfoItem } from '../components/types/tokenTypes';
import numeral from 'numeral';
import BigNumber from 'bignumber.js';
import { TxContext } from '../contexts/TxProvider';

const TokenPage = () => {
  const { tokens } = useContext(UserContext);
  const { addPendingAlert } = useContext(TxContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { mint } = useParams();

  const [buyMint, setBuyMint] = useState<string | null>(null);
  const [sellMint, setSellMint] = useState<string | null>(null);
  const [flipped, setFlipped] = useState(false);

  const [moreInfoData, setMoreInfoData] = useState<{
    infoItems: MoreInfoItem[];
    tokenName: string;
  } | null>(null);

  const token = tokens.find((t) => t.mint === mint);

  if (!token) {
    return <Navigate to={'/main'} />;
  }

  const openMoreInfoModalHandler = (token: CardToken) => {
    setMoreInfoData(mapCardTokenToMoreInfoData(token));
  };

  if (moreInfoData) {
    return (
      <div className="p-5 w-full">
        <TokenMoreInfoModal
          moreInfoItems={moreInfoData.infoItems}
          tokenName={moreInfoData.tokenName}
          closeModalHandler={() => setMoreInfoData(null)}
        />
      </div>
    );
  }

  if (buyMint) {
    return (
      <div className="p-5 w-full">
        <BuyTokenModal
          mint={buyMint}
          onButtonCallback={() => {}}
          closeModalCallback={() => setBuyMint(null)}
        />
      </div>
    );
  }

  if (sellMint) {
    return (
      <div className="p-5 w-full">
        <SellTokenModal
          mint={sellMint}
          onButtonCallback={() => {}}
          closeModalCallback={() => setSellMint(null)}
        />
      </div>
    );
  }

  return (
    <div className="flex h-screen px-5 w-full">
      <br />
      <div className="fixed top-4 mb-4 md:w-1/2 sm:w-full">
        <button
          onClick={() => {
            if (location.state?.fromPortfolio !== undefined) {
              navigate('/main', {
                state: {
                  fromPortfolio: location.state?.fromPortfolio
                }
              });
            } else {
              navigate(-1);
            }
          }}
          className="text-2xl py-2 opacity-50 hover:opacity-100 text-4xl z-10">
          <IoIosArrowBack />
        </button>
      </div>

      <div className="m-auto w-full">
        {token.pnl !== null && token.pnl.percentageChange && token.pnl.shareUrl && (
          <div className="z-10 left-0 right-0 w-auto absolute top-6 pointer-events-none">
            <button
              className="pointer-events-auto mx-auto font-sf-bold px-4 py-1 border border-[#2c2e30] flex items-center justify-center bg-[#161616] text-white rounded-2xl"
              onClick={() => {
                const url = token?.pnl?.shareUrl as string;

                var textArea = document.createElement('textarea');
                textArea.value = url;

                // Avoid scrolling to bottom
                textArea.style.top = '0';
                textArea.style.left = '0';
                textArea.style.position = 'fixed';

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                  var successful = document.execCommand('copy');
                  if (successful) {
                    navigator.clipboard.writeText(url);
                    addPendingAlert({
                      successText: 'Copied to clipboard',
                      pendingText: '',
                      failedText: ``,
                      status: successful ? 'success' : 'failed'
                    });
                    console.log('ss', successful);
                  }
                } catch (err) {
                  console.error('Fallback: Oops, unable to copy', err);
                }

                document.body.removeChild(textArea);
                const pnl = numeral(token?.pnl?.percentageChange?.toFixed(2))?.format('0.00a');
                if (navigator.share) {
                  navigator
                    .share({
                      title: `Got ${pnl}% PNL with ${token?.symbol} on Swipe.fun`,
                      url
                    })
                    .then(() => {})
                    .catch(console.error);
                }
              }}>
              <div className="text-sm mr-2">{token.pnl.percentageChange > 0 ? '☝️' : '👇'}</div>
              {token.pnl.percentageChange > 0 ? (
                <span className="text-green-positive ">
                  {numeral(token.pnl.percentageChange.toFixed(2)).format('0.00a')}%
                </span>
              ) : (
                <span className="text-red-500 font-sf-heavy">
                  {numeral(token.pnl.percentageChange.toFixed(2)).format('0.00a')}%
                </span>
              )}
              <span className="ml-2">Share PnL</span>
            </button>
          </div>
        )}

        <div className="flex flex-col justify-around h-full pb-12 single-card-wrapper">
          <Card
            token={tokenMintToTokenCard(token)}
            flipped={flipped}
            handleFlip={() => setFlipped(!flipped)}
            isTopCard={true}
            swipePage={false}
            openMoreInfoModal={(token: CardToken) => openMoreInfoModalHandler(token)}
          />

          {token.mint !== SOL_MINT && (
            <div className="buttons-single-token-page">
              <PrimaryButton
                onClick={() => {
                  if (token?.userToken !== null) {
                    setSellMint(token.mint);
                  }
                }}
                fullWidth={true}>
                {token?.userToken !== null ? 'Sell' : <span className="line-through">Sell</span>}
              </PrimaryButton>
              <PrimaryButton text="Buy" onClick={() => setBuyMint(token.mint)} fullWidth={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenPage;
