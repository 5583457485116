import React, { ReactNode, useCallback, useMemo } from 'react';

import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { UnifiedWalletProvider } from '@jup-ag/wallet-adapter';

export const WalletConnectionProvider = ({ children }: { children: ReactNode }) => {
  const network = WalletAdapterNetwork.Mainnet;

  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
    [network]
  );

  return (
    <UnifiedWalletProvider
      wallets={wallets}
      config={{
        autoConnect: false,
        env: 'mainnet-beta',
        theme: 'dark',
        metadata: {
          name: 'Swipe.fun',
          description: 'Get Ready To Roll',
          url: 'https://swipe.fun',
          iconUrls: ['https://swipe.fun/android-chrome-256x256.png']
        }
      }}>
      <>{children}</>
    </UnifiedWalletProvider>
  );
};

export default WalletConnectionProvider;
