import React, { useCallback, useContext, useEffect, useState } from 'react';
import PrimaryButton from '../components/ui/PrimaryButton';
import { useUnifiedWalletContext } from '@jup-ag/wallet-adapter';
import { useNavigate } from 'react-router-dom';
import { getRef, UserContext } from '../contexts/UserProvider';
import useProxyWallet from '../hooks/proxyWallet';
import { usePostHog } from 'posthog-js/react';
import { apiUrl } from '../config';

const LandingPage = () => {
  const posthog = usePostHog();
  const { defaultBuySolAmount } = useContext(UserContext);
  const { setShowModal } = useUnifiedWalletContext();
  const { connected, publicKey } = useProxyWallet();
  const navigate = useNavigate();

  // const [accessCode, setAccessCode] = useState('');
  // const [usedAccessCode, setUsedAccessCode] = useState<null | string>(null);
  // const [showWrongCode, setShowWrongCode] = useState<null | string>(null);

  // useEffect(() => {
  //   const _accessToken = localStorage.getItem('_at');
  //
  //   if (_accessToken) {
  //     fetch(`${apiUrl}/v1/access-code/verify`, {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         token: _accessToken
  //       })
  //     })
  //       .then((response) => response.json())
  //       .then((json) => {
  //         if (json.data.code) {
  //           setUsedAccessCode(json.data.code);
  //         }
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, []);

  useEffect(() => {
    if (connected) {
      if (publicKey) {
        try {
          posthog?.identify(publicKey.toBase58());
          posthog?.capture('connect', { publicKey: publicKey.toBase58(), ref: getRef() });
        } catch (e) {
          console.error(e);
        }
      }

      if (localStorage.getItem('tutorialSeen') === null) {
        navigate('/tutorial');
      } else if (defaultBuySolAmount.isZero()) {
        navigate('/onboarding');
      } else {
        navigate('/main');
      }
    }
  }, [connected, navigate, publicKey]);

  // const checkAccessCode = useCallback(() => {
  //   if (accessCode && publicKey) {
  //     fetch(`${apiUrl}/v1/access-code/check`, {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         code: accessCode,
  //         publicKey,
  //         referer: !!document.referrer ? document.referrer : undefined
  //       })
  //     })
  //       .then((response) => response.json())
  //       .then((json) => {
  //         if (!json.data.token) {
  //           throw new Error(json.data.message ?? 'Invalid access code');
  //         }
  //         localStorage.setItem('_at', json.data.token);
  //         setUsedAccessCode(accessCode);
  //       })
  //       .catch((error) => {
  //         console.log('access error', error.message);
  //         setShowWrongCode(error.message);
  //         setAccessCode('');
  //         setTimeout(() => {
  //           setShowWrongCode(null);
  //         }, 1500);
  //       });
  //   }
  //
  //   // if (validPasscodes.includes(passcode) || inLocalStorage) {
  //   //   if (passcode) {
  //   //     localStorage.setItem('_p', passcode);
  //   //   }
  //   //   setShowModal(true);
  //   // }
  //   //
  //   // if (!validPasscodes.includes(passcode)) {
  //   //   setShowWrongPass(true);
  //   //   setPasscode('');
  //   //   setTimeout(() => {
  //   //     setShowWrongPass(false);
  //   //   }, 1500);
  //   // }
  // }, [accessCode, publicKey]);

  return (
    <div className="landing-page">
      <div className="landing-coins-wrapper fixed z-1  md:w-1/2 md:top-12 md:left-1/4 sm:w-full sm:h-full sm:top-0 sm:left-0">
        <div className="absolute top-12 left-3 w-24 h-24 animate-float1">
          <img src="/landing/mumu.png" alt="bg1" className="rounded-full" />
        </div>

        <div className="absolute top-24 right-10 w-24 h-24 animate-float3">
          <img src="/landing/bonk.png" alt="bg2" className="rounded-full" />
        </div>

        <div className="absolute top-1/2 -left-5 w-24 h-24 animate-float3">
          <img src="/landing/popcat.png" alt="bg1" className="rounded-full" />
        </div>

        <div className="absolute top-1/2 -right-10 w-24 h-24 animate-float2">
          <img src="/landing/nub.png" alt="bg2" className="rounded-full" />
        </div>

        <div className="absolute bottom-24 left-12 w-24 h-24  animate-float1">
          <img src="/landing/ponke.png" alt="bg3" className="rounded-full " />
        </div>

        <div className="absolute bottom-32 right-10 w-24 h-24 animate-float4">
          <img src="/landing/ginnan.png" alt="bg6" className="rounded-full  " />
        </div>
      </div>

      <div className="flex h-screen">
        <div className="relative z-2 text-center my-auto">
          <main className="getting-started p-12 rounded-lg shadow-lg">
            <div className="relative">
              <img
                src="/landing/logo-beta.png"
                alt="Swipe.fun Logo"
                className="matcher-img mx-auto mb-4"
              />
            </div>
            <h1 className="mb-2 font-bold">
              swipe<span className="text-primary-color">.</span>fun
            </h1>
            <p className="opacity-40 mb-6">Get Ready To Roll</p>

            {/*{connected && (*/}
            {/*  <div className="relative mb-4">*/}
            {/*    <input*/}
            {/*      type="text"*/}
            {/*      placeholder=""*/}
            {/*      className="w-full p-2 pl-10  rounded-2xl bg-[#090B0C] text-white  border-[#2c2e30] border"*/}
            {/*      value={accessCode}*/}
            {/*      onChange={(e) => setAccessCode(e.target.value)}*/}
            {/*    />*/}
            {/*    <span className="absolute left-3 top-2.5 text-gray-400">🔑</span>*/}
            {/*    {showWrongCode && (*/}
            {/*      <div className="text-5xl w-full -m-10 absolute z-10 mx-auto">🙅‍♀️</div>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*)}*/}

            {!connected && (
              <PrimaryButton
                text="Get started"
                callback={() => setShowModal(true)}
                fullWidth={true}
              />
            )}

            {/*{connected && (*/}
            {/*  <PrimaryButton text="Enter" callback={() => checkAccessCode()} fullWidth={true} />*/}
            {/*)}*/}

            {/*{connected && (*/}
            {/*  <div className="absolute left-0 right-0 -bottom-5 mt-2">*/}
            {/*    No code?{' '}*/}
            {/*    <a*/}
            {/*      className="text-primary-color cursor-pointer"*/}
            {/*      onClick={() => {*/}
            {/*        window.open('https://7l8qnwofwk1.typeform.com/swipe-waitlist', '_blank');*/}
            {/*      }}>*/}
            {/*      Join waitlist*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*)}*/}
          </main>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
